<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import UsersData from "./users-data";
import AddUser from "./add-user"

import {
  layoutComputed,
  authComputed,
  usersMethods,
  usersComputed,
} from "@/state/helpers";
/**
 * Users component
 */
export default {
  page: {
    title: "Users",
    meta: [{ name: "Users" }],
  },
  components: {
    Layout,
    PageHeader,
    UsersData,
    AddUser,
  },
  mounted() {
    this.retrieveUsers();
  },
  data() {
    return {
      title: "titles.users.text",

      busy: false,

      usersData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...usersComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.users.text"),
          active: true,
        },
      ]
    },
  },
  methods: {
    ...usersMethods,

    retrieveUsers() {
      if (this.getUsers.results) {
        this.usersData = this.getUsers.results;
      } else {
        this.busy = true;
        this.getUsersBy({ limit: 100, isDeleted: false }).then(() => {
          this.busy = false;
        });
      }
    },

    openAdd() {
      this.showModalAdd();
    },

    showModalAdd() {
      this.$bvModal.show("add-user-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-user-modal");
    },

    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },

    add(user) {
      this.addUser(user)
        .then((user) => {
          this.makeToast(
            "Add user",
            user.fullName + " has been added",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Add user", error, "danger");
        });
    },

    cancel() {
      return;
    },
    cleanObject(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  watch: {
    getUsers(newVal) {
      this.usersData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addUser.text")}}
        </b-button>
        <AddUser @addUser="add" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <UsersData v-bind:users="usersData" :busy="busy" />
      </div>
    </div>
  </Layout>
</template>