var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-responsive mb-3 bg-white rounded-lg",staticStyle:{"min-height":"430px"}},[_c('b-table',{staticClass:"mb-0",style:({ cursor: `pointer` }),attrs:{"hover":"","show-empty":"","centred":"","busy":_vm.busy,"empty-text":_vm.$t('dataTable.noUsers.text'),"current-page":_vm.currentPage,"per-page":_vm.perPage,"items":_vm.usersList,"fields":_vm.fieldsComputed,"stacked":"sm"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('dataTable.loading.text')))])])]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-inline-flex align-items-center"},[(_vm.userRole != 'ADMIN')?_c('b-button',{staticClass:"mr-2",attrs:{"type":"button","variant":"light"},on:{"click":function($event){return _vm.passUser('edit', data.item)}}},[_vm._v(_vm._s(_vm.$t("buttons.edit.text"))+" ")]):_vm._e(),(
              _vm.userRole == 'ADMIN' &&
              !['SYSADMIN', 'MANAGER'].includes(data.item.role)
            )?_c('b-button',{staticClass:"mr-2",attrs:{"type":"button","variant":"light"},on:{"click":function($event){return _vm.passUser('edit', data.item)}}},[_vm._v(_vm._s(_vm.$t("buttons.edit.text"))+" ")]):_vm._e(),(_vm.userRole == 'SYSADMIN')?_c('b-button',{attrs:{"type":"button","variant":"danger"},on:{"click":function($event){return _vm.passUser('delete', data.item)}}},[_vm._v(_vm._s(_vm.$t("buttons.delete.text"))+" ")]):_vm._e(),(
              _vm.userRole == 'ADMIN' &&
              !['SYSADMIN', 'MANAGER'].includes(data.item.role)
            )?_c('b-button',{attrs:{"type":"button","variant":"danger"},on:{"click":function($event){return _vm.passUser('delete', data.item)}}},[_vm._v(_vm._s(_vm.$t("buttons.delete.text"))+" ")]):_vm._e()],1)]}}])}),_c('EditModal',{attrs:{"user":_vm.user},on:{"editUser":_vm.edit}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"dataTables_paginate paging_simple_numbers float-right"},[_c('ul',{staticClass:"pagination pagination-rounded"},[_c('b-pagination',{attrs:{"total-rows":_vm.users.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }