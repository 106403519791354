<template>
  <div>
    <b-modal
      id="edit-user-modal"
      ref="edit-user-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.updateUser.text')"
    >
      <!-- Name -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="name">{{$t("modals.name.text")}}</label>
          <b-form-input
            id="name"
            v-model="userEdit.name"
            class="mb-1"
            :placeholder="$t('modals.name.text')"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Email -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="email">{{$t("modals.email.text")}}</label>
          <b-form-input
            id="email"
            v-model="userEdit.email"
            class="mb-1"
            :placeholder="$t('modals.email.text')"
            :state="err.email ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.email }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Role -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="role">{{$t("modals.role.text")}}</label>
          <b-form-select
            id="role"
            :options="roles"
            v-model="userEdit.role"
            class="mb-1"
            :state="err.role ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.role }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{$t("buttons.update.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { authComputed } from "@/state/helpers";
export default {
  props: ["user"],
  created() {},
  data() {
    return {
      userEdit: {
        userId: null,
        name: null,
        email: null,
        role: null,
      },
      err: {
        name: null,
        email: null,
        role: null,
      },
    };
  },
  watch: {
    user(newVal) {
      this.userEdit = {
        userId: newVal.id,
        name: newVal.fullName,
        email: newVal.email,
        role: newVal.role,
      };
    },
  },
  computed: {
    ...authComputed,
    roles() {
      if (this.loggedIn.user.role == "MANAGER") {
        return [
          { value: null, text: "-- Role --", disabled: true },
          { value: "MANAGER", text: "MANAGER" },
          { value: "OPERATOR", text: "OPERATOR" },
          { value: "USER", text: "USER" },
        ];
      } else if (this.loggedIn.user.role == "ADMIN") {
        return [
          { value: null, text: "-- Role --", disabled: true },
          { value: "OPERATOR", text: "OPERATOR" },
          { value: "USER", text: "USER" },
        ];
      } else {
        return [
          { value: null, text: "-- Role --", disabled: true },
          { value: "SYSADMIN", text: "SYSADMIN" },
          { value: "ADMIN", text: "ADMIN" },
          { value: "MANAGER", text: "MANAGER" },
          { value: "OPERATOR", text: "OPERATOR" },
          { value: "USER", text: "USER" },
        ];
      }
    },
  },
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      /* this.userEdit = {
        userId: null,
        name: null,
      }; */
      this.err = {
        name: null,
        email: null,
        role: null,
      };
    },
    edit(ok) {
      if (!this.userEdit.name) this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.userEdit.email) this.err.email = this.$t("msgError.obligField.text");
      else this.err.email = "";

      if (!this.err.name &&
          !this.err.email) {
        if (this.userEdit.name != this.user.fullName ||
           this.userEdit.email != this.user.email ||
           this.userEdit.role != this.user.role) {
          ok();
          this.$emit("editUser", this.userEdit);
        } else {
          ok();
        }
      }
    },
  },
};
</script>